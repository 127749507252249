import React from 'react';
import { ArrowRight, Shield, Cpu, Users, Lock, Server, Zap, FileText, Code } from 'lucide-react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 overflow-hidden">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-b from-blue-50 to-white dark:from-gray-800 dark:to-gray-900 pt-20 pb-32">
        {/* Decorative elements */}
        <div className="absolute inset-0">
          <div className="absolute -top-24 -right-24 w-96 h-96 rounded-full bg-blue-100 dark:bg-blue-900/20 blur-3xl opacity-70"></div>
          <div className="absolute bottom-48 -left-24 w-80 h-80 rounded-full bg-indigo-100 dark:bg-indigo-900/20 blur-3xl opacity-60"></div>
          <div className="absolute inset-0 bg-grid opacity-10 dark:opacity-5"></div>
        </div>
      
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 z-10">
          <div className="text-center">
            <div className="inline-block mb-6 py-1 px-3 rounded-full bg-blue-100 dark:bg-blue-900/40 text-blue-700 dark:text-blue-300 text-sm font-medium animate-fade-in">
              Local AI Processing • Data Privacy • Enterprise Solutions
            </div>
            
            <h1 className="text-5xl font-bold mb-6 sm:text-6xl animate-slide-up bg-clip-text text-transparent bg-gradient-to-r from-gray-900 via-blue-800 to-indigo-700 dark:from-white dark:via-blue-400 dark:to-indigo-400">
              Your Data, Our AI, Your Solution
            </h1>
            
            <p className="text-xl text-gray-600 dark:text-gray-300 mb-8 max-w-3xl mx-auto animate-slide-up" style={{ animationDelay: '100ms' }}>
              Enterprise-grade AI solutions that keep your data in your control. 
              Process sensitive information locally with state-of-the-art AI models.
            </p>
            
            <div className="flex flex-col sm:flex-row justify-center gap-4 animate-slide-up" style={{ animationDelay: '200ms' }}>
              <Link 
                to="/meetingblock/try" 
                className="btn-primary"
              >
                Try MeetingBlock
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
              <Link 
                to="/waitlist" 
                className="btn-secondary"
              >
                Join Waitlist
              </Link>
            </div>
          </div>
        </div>
        
        {/* Wave divider */}
        <div className="absolute bottom-0 left-0 right-0">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 80" className="w-full" preserveAspectRatio="none">
            <path 
              fill="currentColor" 
              className="text-white dark:text-gray-800"
              d="M0,32L80,42.7C160,53,320,75,480,74.7C640,75,800,53,960,48C1120,43,1280,53,1360,58.7L1440,64L1440,80L1360,80C1280,80,1120,80,960,80C800,80,640,80,480,80C320,80,160,80,80,80L0,80Z"
            ></path>
          </svg>
        </div>
      </section>

      {/* Problem Statement */}
      <section className="py-24 bg-white dark:bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="section-title">
              The Privacy Paradox in Modern AI
            </h2>
            <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
              Modern AI solutions come with a significant trade-off: powerful capabilities at the cost of data privacy and control.
            </p>
          </div>
          
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 stagger-children">
            {[
              {
                icon: Lock,
                title: 'Data Security Risk',
                description: 'Sensitive information leaving your secure environment creates vulnerabilities and exposure risks.'
              },
              {
                icon: Server,
                title: '3rd Party Dependence',
                description: 'Reliance on external services for critical operations puts you at the mercy of their availability and policies.'
              },
              {
                icon: Shield,
                title: 'Limited Control',
                description: 'Reduced oversight over data processing and storage methods means less ability to enforce your standards.'
              },
              {
                icon: Cpu,
                title: 'Compliance Concerns',
                description: 'Meeting regulatory requirements across regions becomes increasingly complex with cloud-based AI solutions.'
              }
            ].map((item, index) => (
              <div key={index} className="card-hover p-6">
                <div className="w-14 h-14 mb-5 rounded-xl flex items-center justify-center bg-gradient-to-br from-blue-600 to-blue-700 text-white shadow-lg shadow-blue-500/20">
                  <item.icon className="w-7 h-7" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
                  {item.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Solution Section */}
      <section className="py-24 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800 relative">
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="absolute top-1/3 -right-64 w-96 h-96 rounded-full bg-blue-100 dark:bg-blue-900/30 blur-3xl opacity-60"></div>
        </div>
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16">
            <h2 className="section-title">
              Local AI, Unlimited Potential
            </h2>
            <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
              Process your data locally with enterprise-grade AI models, maintaining complete control and security.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8 stagger-children">
            {[
              {
                icon: Shield,
                title: 'Data Sovereignty',
                description: 'Your sensitive data never leaves your secure environment. Process everything locally with complete control.'
              },
              {
                icon: Zap,
                title: 'Advanced Local Processing',
                description: 'State-of-the-art AI models optimized for local deployment, delivering powerful capabilities without compromises.'
              },
              {
                icon: Server,
                title: 'Seamless Integration',
                description: 'Easily integrate with your existing workflows and security protocols, enhancing your systems rather than replacing them.'
              }
            ].map((item, index) => (
              <div 
                key={index}
                className="glass-card p-8 hover:shadow-xl transition-all duration-300"
              >
                <div className="w-14 h-14 mb-5 rounded-xl flex items-center justify-center bg-gradient-to-br from-blue-600 to-blue-700 text-white shadow-lg shadow-blue-500/20">
                  <item.icon className="w-7 h-7" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
                  {item.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* MeetingBlock Showcase */}
      <section className="py-24 bg-white dark:bg-gray-800 relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-16 items-center">
            <div className="animate-slide-in-left">
              <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-6 relative inline-block">
                Introducing MeetingBlock
                <div className="absolute -bottom-2 left-0 right-0 h-1 bg-gradient-to-r from-blue-600 to-indigo-500 rounded-full transform scale-x-30"></div>
              </h2>
              <p className="text-lg text-gray-600 dark:text-gray-300 mb-8">
                Transform your meeting transcripts into structured, actionable notes with local AI processing. Maintain complete privacy while boosting productivity.
              </p>
              <ul className="space-y-4 mb-8">
                {[
                  'Process meetings locally with AI',
                  'Generate structured notes and action items',
                  'Search and analyze meeting history*',
                  'Maintain complete data privacy'
                ].map((feature, index) => (
                  <li key={index} className="flex items-center gap-3 bg-blue-50 dark:bg-blue-900/20 py-2 px-4 rounded-lg animate-fade-in" style={{ animationDelay: `${index * 150}ms` }}>
                    <div className="flex-shrink-0 w-8 h-8 rounded-full bg-blue-100 dark:bg-blue-800 flex items-center justify-center text-blue-600 dark:text-blue-300">
                      <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <span className="text-gray-700 dark:text-gray-200">{feature}</span>
                  </li>
                ))}
              </ul>
              <div className="flex flex-col sm:flex-row gap-4">
                <Link 
                  to="/meetingblock/try"
                  className="btn-primary group"
                >
                  Try Now
                  <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
                </Link>
                <Link 
                  to="/meetingblock"
                  className="btn-secondary"
                >
                  Learn More
                </Link>
              </div>
            </div>
            <div className="mt-12 lg:mt-0 animate-slide-in-right">
              <div className="relative">
                {/* Product showcase */}
                <div className="relative aspect-video rounded-2xl bg-gradient-to-br from-blue-50 to-indigo-50 dark:from-gray-800 dark:to-blue-900/30 shadow-2xl overflow-hidden border border-gray-100 dark:border-gray-700">
                  <div className="absolute inset-0 flex flex-col">
                    {/* Header */}
                    <div className="h-12 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 flex items-center px-4">
                      <div className="flex space-x-2">
                        <div className="w-3 h-3 rounded-full bg-red-500"></div>
                        <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                        <div className="w-3 h-3 rounded-full bg-green-500"></div>
                      </div>
                      <div className="mx-auto text-sm font-medium text-gray-600 dark:text-gray-300">MeetingBlock</div>
                    </div>
                    
                    {/* Content */}
                    <div className="flex-1 p-6 flex">
                      <div className="w-1/3 pr-4">
                        <div className="h-8 w-full bg-blue-100 dark:bg-blue-900/50 rounded-lg mb-4"></div>
                        <div className="space-y-3">
                          <div className="h-4 w-full bg-gray-100 dark:bg-gray-700 rounded"></div>
                          <div className="h-4 w-4/5 bg-gray-100 dark:bg-gray-700 rounded"></div>
                          <div className="h-4 w-3/4 bg-gray-100 dark:bg-gray-700 rounded"></div>
                        </div>
                      </div>
                      <div className="w-2/3">
                        <div className="h-8 w-full bg-blue-100 dark:bg-blue-900/50 rounded-lg mb-4"></div>
                        <div className="space-y-3">
                          <div className="h-4 w-full bg-gray-100 dark:bg-gray-700 rounded"></div>
                          <div className="h-4 w-full bg-gray-100 dark:bg-gray-700 rounded"></div>
                          <div className="h-4 w-4/5 bg-gray-100 dark:bg-gray-700 rounded"></div>
                          <div className="h-4 w-5/6 bg-gray-100 dark:bg-gray-700 rounded"></div>
                          <div className="h-4 w-3/4 bg-gray-100 dark:bg-gray-700 rounded"></div>
                        </div>
                        
                        <div className="mt-8">
                          <div className="h-6 w-1/2 bg-blue-100 dark:bg-blue-900/50 rounded mb-3"></div>
                          <div className="flex space-x-3 mb-4">
                            <div className="h-9 w-1/3 bg-blue-600 rounded"></div>
                            <div className="h-9 w-1/3 bg-gray-200 dark:bg-gray-700 rounded"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  {/* Animated elements */}
                  <div className="absolute top-40 left-64 w-16 h-16 rounded-full bg-blue-400 opacity-20 animate-pulse"></div>
                  <div className="absolute bottom-20 right-32 w-24 h-24 rounded-full bg-indigo-400 opacity-20 animate-ping" style={{ animationDuration: '3s' }}></div>
                </div>
                
                {/* Decorative elements */}
                <div className="absolute -bottom-6 -right-6 w-32 h-32 bg-gradient-to-br from-blue-600 to-indigo-600 rounded-2xl -z-10 opacity-20 blur-xl"></div>
                <div className="absolute -top-6 -left-6 w-32 h-32 bg-gradient-to-br from-blue-400 to-blue-600 rounded-full -z-10 opacity-20 blur-xl"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ComputeBlock Teaser */}
      <section className="py-24 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800 relative overflow-hidden">
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="absolute top-1/3 left-0 w-full h-1/2 bg-gradient-to-r from-blue-50/50 to-transparent dark:from-blue-900/10 dark:to-transparent"></div>
          <div className="absolute bottom-0 right-0 w-1/2 h-1/2 bg-gradient-to-tl from-indigo-50/50 to-transparent dark:from-indigo-900/10 dark:to-transparent"></div>
        </div>
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative z-10">
          <div className="inline-block mb-6 py-1 px-3 rounded-full bg-blue-100 dark:bg-blue-900/40 text-blue-700 dark:text-blue-300 text-sm font-medium">
            Coming Soon
          </div>
          
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            <span className="text-gradient">
              ComputeBlock
            </span> - Amplify Your AI Capabilities
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 mb-8 max-w-3xl mx-auto">
            Enhance your local AI processing capabilities with our enterprise-ready computing solution.
          </p>
          
          <div className="max-w-4xl mx-auto glass-card p-8 text-left mb-12">
            <div className="grid md:grid-cols-3 gap-6">
              <div className="space-y-3">
                <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-blue-600 to-blue-700 flex items-center justify-center text-white shadow-lg shadow-blue-500/20">
                  <Cpu className="w-6 h-6" />
                </div>
                <h3 className="font-semibold text-gray-900 dark:text-white">Dedicated AI Hardware</h3>
                <p className="text-gray-600 dark:text-gray-300 text-sm">Hardware acceleration for local AI models.</p>
              </div>
              
              <div className="space-y-3">
                <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-blue-600 to-blue-700 flex items-center justify-center text-white shadow-lg shadow-blue-500/20">
                  <FileText className="w-6 h-6" />
                </div>
                <h3 className="font-semibold text-gray-900 dark:text-white">Document Management</h3>
                <p className="text-gray-600 dark:text-gray-300 text-sm">Advanced document processing and analysis.</p>
              </div>
              
              <div className="space-y-3">
                <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-blue-600 to-blue-700 flex items-center justify-center text-white shadow-lg shadow-blue-500/20">
                  <Code className="w-6 h-6" />
                </div>
                <h3 className="font-semibold text-gray-900 dark:text-white">API Integration</h3>
                <p className="text-gray-600 dark:text-gray-300 text-sm">Seamless integration with existing systems.</p>
              </div>
            </div>
          </div>
          
          <button className="btn-secondary">
            Stay Updated
          </button>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-24 bg-gradient-to-b from-white to-gray-50 dark:from-gray-800 dark:to-gray-900 relative overflow-hidden">
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="absolute bottom-0 right-0 w-full h-1/2 bg-gradient-to-tl from-blue-50/30 to-transparent dark:from-blue-900/5 dark:to-transparent"></div>
        </div>
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative z-10">
          <h2 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Ready to Take Control?
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 mb-12 max-w-3xl mx-auto">
            Start with MeetingBlock and experience the power of local AI processing.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <Link 
              to="/waitlist"
              className="btn-primary group"
            >
              Get Started
              <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
            </Link>
            <Link 
              to="/about"
              className="btn-secondary"
            >
              Learn About Us
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;